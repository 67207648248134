import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Assets/Css/Web.css";
import ErrorOrder from "../Assets/Images/website-error-frustrated-distressed-girl-staring-laptop-modern-flat-colorful-vector-illustration-website_174639-55609.jpg"
import Success_Order from "../Assets/Images/istockphoto-182436004-612x612.jpg"
import { useParams, useLocation, useNavigate } from "react-router-dom";
import calendar from "../Assets/Images/arrow-3.png";
import global from "../Assets/Images/calendar.png";
import Data from "../Assets/Images/global.png";
import Dollar from "../Assets/Images/dollar-circle.png";
import Dropdown from "../Package/dropdown";
import axios from "axios";
import { axiosConfig } from "../Login/axiosAPI";
import parse from "html-react-parser";
import Swal from "sweetalert2";

function SuccessOrder() {
  const { status,  message,  id } = useParams()
  // const location = useLocation();
  // const [post, setPost] = useState({});
  // useEffect(() => {
  //   setPost(location);
  // }, []);
  console.log(status);
  console.log(message);
  console.log(id);

  
  let [data, setData] = useState([]);
  const getData = async () => {
      try {
          const response = await axiosConfig.get(
            `https://tejwal-back.addictaco.com/api/airalo/order/${id}`,
            {}
          );
          setData(response.data.data);
          console.log(response.data)
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
  };
  
  useEffect(() => {
      getData();
  }, []);

    return (
      <>
        <div className="container">
          {status == "true" ? (
            <div className="error-order">
              {/* <img alt="error" src={Success_Order} /> */}
              <h1>Success Order</h1>
              <div className="w-100">
                <div className="row">
                  {data.map((x, index) => (
                    <div className="col-md-6" key={index}>
                      <div className="">
                        <div className="global-package">
                          <div className="">
                            <h1>{x?.esim_info?.simable?.package}</h1>
                          </div>
                          <div className="p-3">
                            {/* <div className="data">
                              <h3>
                                <span>
                                  <img src={Data} alt="Global" />
                                  Coverage
                                </span>
                                <strong className="">{x.title}</strong>
                              </h3>
                            </div> */}
                            <div className="data">
                              <h3>
                                <span>
                                  <img src={calendar} alt="arrows" />
                                  Data
                                </span>
                                <strong>{x?.esim_info?.simable?.data}</strong>
                              </h3>
                            </div>
                            <div className="data">
                              <h3>
                                <span>
                                  <img src={global} alt="Calendar" />
                                  Validity
                                </span>
                                <strong>
                                  {x?.esim_info?.simable?.validity} Days
                                </strong>
                              </h3>
                            </div>
                            <div className="data">
                              <h3>
                                <span>
                                  <img src={Dollar} alt="Calendar" />
                                  Price
                                </span>
                                <strong>{x.price} $</strong>
                              </h3>
                            </div>
                            <div className="data">
                              <h3>
                                <span>
                                  <img src={Dollar} alt="Calendar" />
                                  Status
                                </span>
                                <strong>{x.esim_usage?.status} </strong>
                              </h3>
                            </div>
                            <Dropdown />
                            <button
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              className="submit"
                            >
                              Install Now
                              <i className="fa fa-chevron-right"></i>
                            </button>
                            <div
                              className="modal fade"
                              id="exampleModal"
                              tabIndex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-body">
                                    <div>
                                      <img
                                        src={x.esim_info?.qrcode_url}
                                        alt="QR Code"
                                        className="QR"
                                      />
                                      <p>
                                        {parse(
                                          `${x.esim_info?.simable?.manual_installation}`
                                        )}
                                      </p>
                                      <button
                                        type="button"
                                        className="submit"
                                        data-bs-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="error-order">
              <img alt="error" src={ErrorOrder} />
              <h1>Error</h1>
              <Link to="/" className="submit pt-3 pb-3">
                Go To Home
              </Link>
            </div>
          )}
        </div>
      </>
    );

}

export default SuccessOrder;
