import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import calendar from "../Assets/Images/arrow-3.png";
import global from "../Assets/Images/calendar.png";
import Data from "../Assets/Images/global.png";
import Dollar from "../Assets/Images/dollar-circle.png";
import Egypt_Flag from "../Assets/Images/Frame 1261155613.png";
import "../Assets/Css/Web.css";
import axios from "axios";
import { axiosConfig } from "../Login/axiosAPI";
import Swal from "sweetalert2";
import Dropdown from "../Package/dropdown";
import Quantity from "../Package/Quantity";
import { useAuth } from "../Login/AuthProvider";
import Modal from "react-bootstrap/Modal";
// import Card from "./Card";
import { yellow } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router";

function OrderPage(params) {

    const auth = useAuth();
  const location = useLocation();
  const [post, setPost] = useState({});
  const [code, setCode] = useState({});
  useEffect(() => {
    setPost(location.state.item);
    setCode(location.state.code);
  }, []);
  console.log(location);



  const [dataFromChild, setDataFromChild] = useState("");

  function handleDataFromChild(counter) {
    setDataFromChild(counter);
  }

  console.log(dataFromChild);


    const postPayment = async (p) => {
      const response = await axiosConfig({
        method: "post",
        url: "https://tejwal-back.addictaco.com/api/get-payment-url",
        data: {
          quantity: dataFromChild == "" ? 1 : dataFromChild,
          package_id: post.id,
          type: post.type,
          description: post.id,
          price: post.price,
          code: code.code,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
      })
        .then((res) => {
            window.location = `${res.data.data}`;
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "You Should Login First",
          });
        });
    };

  return (
    <>
        <div className="container">
            <div className="row">
                <div className="text-center">
                    <h1 className="font-la mb-4">My Orders</h1>
                </div>
                <div className="col-md-4">
                    <div className="">
                    <div className="global-package">
                        <div className="">
                        <h1>{post?.title}</h1>
                        </div>
                        <div className="p-3">
                        {/* <div className="data">
                            <h3>
                            <span>
                                <img src={Data} alt="Global" />
                                Coverage
                            </span>
                            <strong className="">{country.title}</strong>
                            </h3>
                        </div> */}
                        <div className="data">
                            <h3>
                            <span>
                                <img src={calendar} alt="arrows" />
                                Data
                            </span>
                            <strong>{post?.data}</strong>
                            </h3>
                        </div>
                        <div className="data">
                            <h3>
                            <span>
                                <img src={global} alt="Calendar" />
                                Validity
                            </span>
                            <strong>{post?.day} Days</strong>
                            </h3>
                        </div>
                        <div className="data">
                            <h3>
                            <span>
                                <img src={Dollar} alt="Calendar" />
                                Price
                            </span>
                            <strong>{post?.price} $</strong>
                            </h3>
                        </div>
                        <Quantity sendDataToParent={handleDataFromChild} />
                        <Dropdown />
                        </div>
                        <div className="text-center mt-5 mb-5">
                        <button
                            className="submit pt-3 pb-3"
                            onClick={() => postPayment()}
                        >
                            Buy Now
                            <i className="fa fa-chevron-right"></i>
                        </button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default OrderPage;
