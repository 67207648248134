import React, { useState, useEffect } from "react";
import global from "../Assets/Images/calendar.png";

function Quantity({ sendDataToParent }) {
      // const [counter, setCounter] = useState(props.counter);
      const [counter, setCounter] = useState(1);
      const incremented = () => {
        setCounter(counter + 1);
      };
      const decremented = () => {
        if (counter == 1) {
          return;
        } else {
          setCounter(counter - 1);
        }
      };

      // const [numCounter, setNumCounter] = useState("");

      function handleClick() {
        sendDataToParent(counter);
      }

  return (
    <>
      <div className="data">
        <h3>
          <span>
            <img src={global} alt="Calendar" />
            Quantity
          </span>
          <div className="d-flex justify-content-between align-items-center counter-button">
            <span>{counter}</span>
            <div className="d-flex flex-column">
              <button className="" onClick={() => {incremented(); handleClick();}}>
                <i className="fa fa-chevron-up"></i>
              </button>
              <button className="" onClick={() => {decremented(); handleClick();}}>
                <i className="fa fa-chevron-down"></i>
              </button>
            </div>
          </div>
        </h3>
      </div>
    </>
  );
}

export default Quantity;
